import { ShareIcon } from '@heroicons/react/20/solid'
import { Link, useParams } from '@remix-run/react'
import { type PropsWithChildren } from 'react'
import { useHydrated } from 'remix-utils/use-hydrated'
import { DEFAULT_LOCALE } from '#app/data/config'
import { SITE_NAME } from '#app/env.remix/config'

export function MdxLink({
	children,
	href,
}: PropsWithChildren<{
	href: string
}>): JSX.Element {
	const params = useParams()
	const locale = params.locale || DEFAULT_LOCALE

	if (href.startsWith('/')) {
		const hasLocale = href.substring(3, 1) === '/'
		return <Link to={hasLocale ? href : `/${locale}${href}`}>{children}</Link>
	}

	if (href.startsWith('#')) {
		return <Link to={href}>{children}</Link>
	}

	return (
		<a href={href} rel="noreferrer" target="_blank">
			{children}
		</a>
	)
}

/* eslint-disable jsx-a11y/alt-text */

export function MdxImage({
	caption,
	...props
}: JSX.IntrinsicElements['img'] & { caption?: string }) {
	return (
		<figure>
			<img loading="lazy" {...props} />
			{caption ? <figcaption>{caption}</figcaption> : null}
		</figure>
	)
}

export function MdxShareButton({
	label,
	sharedUrl,
}: {
	label: string
	sharedUrl: string
}) {
	let isHydrated = useHydrated()

	return isHydrated && 'share' in navigator ? (
		<div className="flex justify-center">
			<button
				type="button"
				className="m-2 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 hover:text-slate-50 focus:outline-none"
				onClick={() =>
					navigator.share({
						title: SITE_NAME,
						text: 'Look at this',
						url: sharedUrl,
					})
				}
			>
				<ShareIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
				{label}
			</button>
		</div>
	) : null
}

export function MdxTable({ children }: PropsWithChildren<void>) {
	return (
		<div className="not-prose w-full overflow-x-auto py-4">
			<table className="table-markdown">{children}</table>
		</div>
	)
}

// SF220523 lazy loading does not work when inside mdx
// if put pic of youtuble -> click -> load
// to still benefit from seo - add a video schema
// export function MdxYouTube({ src }: { src: string }) {
//   return (
//     <div className="my-8">
//       <iframe
//         className="aspect-video w-full"
//         src={src}
//         frameBorder="0"
//         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//         allowFullScreen
//         loading="lazy"
//       ></iframe>
//     </div>
//   );
// }
